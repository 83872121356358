import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { AbonosService } from "../service/contabilidad/AbonosService";
//import { MyLoader } from '../components/placeholders/MyLoader';
import { Password } from "primereact/password";
import { ListBox } from "primereact/listbox";
import { Message } from "primereact/message";
import ContentLoader, { List } from "react-content-loader";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { useAuth0 } from "@auth0/auth0-react";
import { RolesModulosPorUsuarioService } from "../service/administracion/RolesModulosPorUsuarioService";
import { EmpleadosService } from "../service/administracion/EmpleadosService";
import { VentasPrincipalService } from "../service/ventas/VentasPrincipalService";
import { VentasService } from "../service/ventas/VentasService";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";

const Abonos = () => {
    function currencyFormat(num) {
        return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    /* const updateDropdown = (data) => {
        // cuenta.usuario = data.usuario;
        //setCuenta(cuenta);
        //setUsuario(data)
        //console.log(cuenta)
        console.log(data);
    }; */

    /* const updateFieldListBox = (data) => {
        try {
            pagoComision.ksPersona.id = data.id;
            pagoComision.ksPersona.nombreCompleto = data.nombreCompleto;
            pagoComision.ksPersona.nombre = data.nombre;
            pagoComision.ksPersona.correoElectronico = data.correoElectronico;

            setPagoComision(pagoComision);
            console.log(data);
            console.log(pagoComision);

            setPersona(data);
        } catch (e) {
            console.log("Error");
        }
    }; */

    //const [checkboxValue, setCheckboxValue] = useState([]);

    /* const onCheckboxChange = (e, name) => {
        setPagoComision({
            ...pagoComision,
            [`${name}`]: e.checked,
        });
    }; */

    let emptyAbonos = {
        id: "",
        importe: 0,
        saldoActual: 0,
        fechaCreacion: "",
        comentarios: "",
        pagoNumero: 0,
        usuarioUm: "",
        status: "",
        usuarioAprobador: "",
        origenAbono: "",
        ksCuentas: {
            id: "",
            cuenta: "",
        },
        ksCuentaPaga: {
            id: "",
            fiador: "",
        },
        ksFormaPago: {
            id: "",
            formaPago: "",
        },
        ksVenta: {
            id: "",
            costo: 0,
        },
    };

    let emptyPersona = {
        id: "",
        nombreCompleto: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        celular: "",
        correoElectronico: "",
    };

    let emptyRol = {
        id: "",
        rol: "",
    };

    const limpiarFormulario = () => {
        setCuenta(null);
        setComentario("");
        setVenta(null);
        setFormaPago(null);
        setFechaAbono(null);
        setImporte(0);
        setSaldoActual(0);
        setTotalImportes(0);
        setNumeroAbonoActual(0);
        setNumeroAbonoEnviar(0);
        setSaldoInicial(0);
        setSaldoOriginal(0);
        setValidacionImporte("");
        setDetalleVenta(null);
        setDetalleVentaSeleccionada(null);
    };

    const limpiarObjetoAbonos = () => {
        setPagoComision({});
    };

    const [abonos, setAbonos] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [empleados, setEmpleados] = useState([]);
    const [empleado, setEmpleado] = useState(null);
    const [comentario, setComentario] = useState("");
    const [cuentas, setCuentas] = useState([]);
    const [cuenta, setCuenta] = useState(null);
    const [venta, setVenta] = useState(null);
    const [detalleVenta, setDetalleVenta] = useState([]);
    const [detalleVentaSeleccionada, setDetalleVentaSeleccionada] = useState(null);

    const [validacionImporte, setValidacionImporte] = useState("");
    const [ventas, setVentas] = useState([]);
    const [ventasDropdown, setVentasDropdown] = useState([]);
    const [formaPago, setFormaPago] = useState(null);
    const [formaPagoDropdown, setFormaPagoDropdown] = useState([]);
    const [importe, setImporte] = useState(0);
    const [fechaAbono, setFechaAbono] = useState(null);
    const [numeroAbonoEnviar, setNumeroAbonoEnviar] = useState(0);
    const [numeroAbonoActual, setNumeroAbonoActual] = useState(0);
    const [totalImportes, setTotalImportes] = useState(0);
    const [saldoInicial, setSaldoInicial] = useState(0);
    const [saldoOriginal, setSaldoOriginal] = useState(0);
    const [saldoActual, setSaldoActual] = useState(0);
    const [status, setStatus] = useState(null);
    const [procesoEtapas, setProcesoEtapas] = useState([]);
    const [procesoEtapa, setProcesoEtapa] = useState(null);
    const [pagosComisionesDialog, setPagosComisionesDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [pagoComision, setPagoComision] = useState(emptyAbonos);
    const [persona, setPersona] = useState(emptyPersona);
    const [rol, setRol] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [isLoading, setLoading] = useState(true);

    const ventasPrincipalService = new VentasPrincipalService();
    const ventasService = new VentasService();
    const abonosService = new AbonosService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();
    //const empleadosService = new EmpleadosService();

    const {
        //isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
    } = useAuth0();

    useEffect(() => {
        if (user) {
            rolesModulosPorUsuarioService.mz(user.email, 9).then((data) => {
                if (!data.samx) {
                    window.location.href = "/";
                } else {
                    abonosService.getAll().then((data) => {
                        setAbonos(data.abonos);
                        setCuentas(data.cuentas);
                        setProcesoEtapas(data.procesoEtapas);
                        setFormaPagoDropdown(data.formaPago);
                        setLoading(false);
                    });
                }
            });
            /* empleadosService.getAll().then((data) => {
                setEmpleados(data.empleados);
            }); */
            ventasPrincipalService.getAll().then((data) => {
                setVentas(data.ventas);
                const ventasOptions = data.ventas.map((venta) => ({
                    label: ` ${currencyFormat(venta.costo)} - ${venta.id} - ${venta.ksCliente.nombreComercial}`,
                    id: venta.id,
                    costo: venta.costo,
                }));
                setVentasDropdown(ventasOptions);
            });
        }
    }, [isLoading, isAuthenticated]);

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return "Selecciona empleados";
    };

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    };

    const openNew = () => {
        //setPagoComision(emptyAbonos);
        //setPersona(emptyPersona);
        //setRol(null);
        //setSubmitted(false);
        //setPagosComisionesDialog(true);
        //console.log("Abrindo modal para crear nuevo abono");
        //console.log("Valor de cuenta: ", cuenta);
        //console.log("Venta seleccionada: ", venta);
        //console.log("Costo de la venta seleccionada: ", costoVenta);

        //const ventaSeleccionada = ventasCargadas.find((v) => v.value === venta) || {};
        /* setPagoComision({
            ...pagoComision,
            ksCuentas: cuenta,
            usuarioAprobador: empleados[0].ksPersona.nombreCompleto,
            usuarioUm: empleados[0].ksPersona.nombreCompleto,
            comentarios: pagoComision.comentarios,
        }); */
        setPagosComisionesDialog(true);
    };

    const hideDialog = () => {
        if (persona.nombreCompleto.trim()) setUsuarios(usuarios.filter((p) => p.id !== persona.id));

        setSubmitted(false);
        setPagosComisionesDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    };

    const construirJsonAbono = (datosOriginales) => {
        const jsonAbono = {
            //ksCuentas: datosOriginales.ksCuentas || null,
            ksCuentas: datosOriginales.ksCuentas ? { id: datosOriginales.ksCuentas.id, cuenta: datosOriginales.ksCuentas.cuenta } : null,
            ksCuentaPaga: null,
            ksFormaPago: datosOriginales.ksFormaPago,
            //ksVenta: datosOriginales.ksVenta ? { id: datosOriginales.ksVenta.id } : null,
            ksVenta: datosOriginales.ksVenta ? { id: detalleVentaSeleccionada } : null,
            importe: datosOriginales.importe,
            saldoActual: datosOriginales.saldoActual,
            fechaCreacion: datosOriginales.fechaCreacion,
            comentarios: datosOriginales.comentarios || "",
            pagoNumero: datosOriginales.pagoNumero,
            usuarioUm: datosOriginales.usuarioUm || "",
            fechaUm: datosOriginales.fechaCreacion,
            status: datosOriginales.status,
            usuarioAprobador: datosOriginales.usuarioAprobador || "",
            origenAbono: datosOriginales.origenAbono || null,
        };
        return jsonAbono;
    };

    const saveAbono = async () => {
        try {
            console.log("Preparando datos para guardar el abono", pagoComision);

            const abonoTransformado = construirJsonAbono(pagoComision);

            console.log("Datos preparados para guardar: ", abonoTransformado);

            const response = await abonosService.create(abonoTransformado);

            console.log("Abono guardado exitosamente: ", response);
            limpiarFormulario();
            limpiarObjetoAbonos();
        } catch (error) {
            console.error("Error al guardar el abono: ", error);
        }
    };

    const pagarComision = (pagoComisionParam) => {
        setLoading(true);
        pagoComision.id = pagoComisionParam.ksProcesoEtapas.id;
        pagoComision.usuarioPago = user.email;
        console.log(abonos);
        console.log(pagoComisionParam);

        abonosService.create(pagoComision).then((data) => {
            setAbonos(abonos.filter((p) => p.ksProcesoEtapas.id !== pagoComisionParam.ksProcesoEtapas.id));
            setPagoComision(emptyAbonos);
            setLoading(false);
        });
        toast.current.show({ severity: "success", summary: "Exitoso", detail: "Se ha regiostrado pago de comisión exitosamente.", life: 3000 });
    };

    /* const confirmDeleteProduct = (pagoComision) => {
        setPagoComision(pagoComision);
        setDeleteClientDialog(true);
    }; */

    const deleteProduct = () => {
        let _pagosComisiones = abonos.filter((val) => val.id !== pagoComision.id);
        setAbonos(_pagosComisiones);
        setDeleteClientDialog(false);
        setPagoComision(emptyAbonos);
        toast.current.show({ severity: "success", summary: "Successful", detail: "pagoComision Deleted", life: 3000 });
    };

    /* const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < abonos.length; i++) {
            if (abonos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }; */

    /* const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }; */

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    /* const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }; */

    const deleteSelectedProducts = () => {
        let _pagosComisiones = abonos.filter((val) => !selectedProducts.includes(val));
        setAbonos(_pagosComisiones);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "abonos Deleted", life: 3000 });
    };

    /* const onCategoryChange = (e) => {
        let _pagoComision = { ...pagoComision };
        _pagoComision["category"] = e.value;
        setPagoComision(_pagoComision);
    }; */

    const formatDate = (date) => {
        if (!date) return null;

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const fetchVentaDetalle = async (idVentaPrincipal) => {
        try {
            const detalle = await ventasService.getAll(idVentaPrincipal);
            console.log("Este es el detalle de la venta seleccionada: ", detalle);
            console.log("Este es el detalle de la venta seleccionada: ", detalle.ventas[0].id);
            console.log("Este es el detalle de la venta seleccionada: ", detalle.ventas[1].id);

            const idsDetalles = detalle.ventas.map((venta) => venta.id);
            setDetalleVenta(idsDetalles);
        } catch (error) {
            console.error("Error al obtener el detalle de la venta: ", error);
        }
    };

    const onInputChange = (e, name) => {
        //console.log(`Valor seleccionado para ${name}: `, e.value);
        const value = (e.target && e.target.value) || "0";
        //let pagoNumeroNuevo = value;
        let fechaFormatted = value;
        let _pagoComision = { ...pagoComision };
        if (name === "ksCuentas") {
            setCuenta(value);
        } else if (name === "comentarios") {
            setComentario(value);
        } else if (name === "ksVenta") {
            setVenta(value);
            const ventaId = value.id;
            const costoVenta = value.costo;
            setSaldoActual(costoVenta);
            fetchVentaDetalle(ventaId);
        } else if (name === "ksFormaPago") {
            setFormaPago(value);
        } else if (name === "fechaCreacion") {
            fechaFormatted = formatDate(value);
            setFechaAbono(fechaFormatted);
        }
        _pagoComision.status = "APROBADO";

        _pagoComision[`${name}`] = value;
        _pagoComision[`${name}`] = fechaFormatted;

        setPagoComision(_pagoComision);
    };

    const onDetalleVentaChange = (e, name) => {
        const value = (e.target && e.target.value) || "0";
        let pagoNumeroNuevo = value;
        let _pagoComision = { ...pagoComision };
        if (name === "ksDetalleVenta") {
            setDetalleVentaSeleccionada(value);
            console.log("este es el value: ", value);
            const abonosFiltrados = abonos.filter((abono) => abono.ksVenta.id === value);
            console.log("Estos son los abonos obtenidos con este id: ", abonosFiltrados);
            let totalDeImportes = 0;
            if (abonosFiltrados.length > 0) {
                let abonosPrevios = abonosFiltrados.length;
                setNumeroAbonoActual(abonosPrevios);
                pagoNumeroNuevo = abonosPrevios + 1;
                setNumeroAbonoEnviar(pagoNumeroNuevo);
                _pagoComision[`pagoNumero`] = pagoNumeroNuevo;

                const importesAcumulados = abonosFiltrados.map((abono) => abono.importe);
                //console.log("Importes acumulados de abonos de esta venta: ", importesAcumulados);

                totalDeImportes = importesAcumulados.reduce((acc, current) => acc + current, 0);
                //console.log("Total de los importes: $", totalDeImportes);
                setTotalImportes(totalDeImportes);

                const saldoInicial = saldoActual - totalDeImportes;
                setSaldoActual(saldoInicial);
                setSaldoInicial(saldoInicial);
                setSaldoOriginal(saldoInicial);
                //console.log("Este es el saldo actual: ", saldoInicial);
                //console.log("Este va ser el saldo inicial: ", saldoInicial);
                _pagoComision["saldoActual"] = saldoInicial;
            } else {
                //console.log("Total de importes: $", totalDeImportes);
                //setTotalImportes(totalDeImportes);
                let abonosPrevios = 0;
                pagoNumeroNuevo = 1;
                const saldoInicial = saldoActual;
                setSaldoActual(saldoInicial);
                setSaldoInicial(saldoInicial);
                setSaldoOriginal(saldoInicial);
                setNumeroAbonoActual(abonosPrevios);
                //console.log("Saldo inicial: ", saldoInicial);
                //console.log("Saldo actual: ", saldoInicial);
                //console.log("Numero de abono actual: ", numeroAbonoActual);

                _pagoComision["saldoActual"] = saldoInicial;
            }
        }
        _pagoComision[`pagoNumero`] = pagoNumeroNuevo;
        _pagoComision[`${name}`] = value;
        setPagoComision(_pagoComision);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _pagoComision = { ...pagoComision };

        if (name === "importe") {
            if (val > saldoOriginal) {
                setValidacionImporte("El importe no puede ser mayor al saldo actual");
                setSaldoActual(saldoOriginal);
            } else if (val === 0) {
                setValidacionImporte("El importe no puede ser 0");
                setSaldoActual(saldoOriginal);
            } else {
                setValidacionImporte("");
                const nuevoSaldo = saldoOriginal - val;
                setSaldoActual(nuevoSaldo);
                _pagoComision["saldoActual"] = nuevoSaldo;
            }
            setImporte(val);
        }

        _pagoComision[`${name}`] = val;

        setPagoComision(_pagoComision);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    /* const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }; */

    /* const comisionodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">comision</span>
                {rowData.comision}
            </>
        );
    }; */

    const origenImporteTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">origenPago</span>
                {currencyFormat(rowData.importe)}
            </>
        );
    };

    const comentariosTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">comentarios</span>
                {rowData.comentarios}
            </>
        );
    };

    const pagoNumeroTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Número de pago</span>
                {rowData.pagoNumero}
            </>
        );
    };

    /* const cuentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">cuenta</span>
                {rowData.ksCuentas.cuenta}
            </>
        );
    }; */

    const fechaCreacionyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">procesoid</span>
                {rowData.fechaCreacion}
            </>
        );
    };

    const saldoActualTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {currencyFormat(rowData.saldoActual)}
            </>
        );
    };

    const ventaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">venta</span>
                {rowData.ksVenta.id}
            </>
        );
    };

    const cuentaXPagarTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">venta</span>
                {rowData.ksCuentaPaga.id}
            </>
        );
    };

    const cuentaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">venta</span>
                {rowData.ksCuentas.id}
            </>
        );
    };

    const formaPagoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">venta</span>
                {rowData.ksFormaPago.formaPago}
            </>
        );
    };

    /* const observacionesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">observaciones</span>
                {rowData.observaciones}
            </>
        );
    }; */

    /* const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox checked={rowData.habilitado} readOnly={true} />
            </>
        );
    }; */

    /* const pagoAnuladoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">pagoAnulado</span>
                <Checkbox checked={rowData.pagoAnulado} readOnly={true} />
            </>
        );
    }; */

    /* const pagarBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">pagar</span>
                <Checkbox checked={rowData.pagar} readOnly={true} />
            </>
        );
    }; */

    /* const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        );
    }; */

    /* const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }; */

    /* const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }; */

    /* const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }; */

    /* const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`client-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    }; */

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-money-bill" className="p-button-rounded p-button-success mr-2" onClick={() => pagarComision(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    };
    //--------------------------------------------
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administración de abonos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveAbono} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    {/* <DataTable ref={dt} value={abonos} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                    {!isLoading && abonos ? (
                        <DataTable
                            ref={dt}
                            value={abonos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} pagos de comisiones"
                            globalFilter={globalFilter}
                            emptyMessage="No se encontraron pagos de comisiones."
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="ksVenta.id" header="ID venta" sortable body={ventaBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="ksCuentaPaga.id" header="ID cuenta por pagar" sortable body={cuentaXPagarTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="ksCuentas.id" header="ID cuenta origen" sortable body={cuentaTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="ksFormaPago.formaPago" header="Forma de pago" sortable body={formaPagoTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="importe" header="Importe" sortable body={origenImporteTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="saldoActual" header="Saldo actual" sortable body={saldoActualTemplate} headerStyle={{ width: "14%", minWidth: "20rem" }}></Column>
                            <Column field="fechaCreacion" header="Fecha de creación" sortable body={fechaCreacionyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="pagoNumero" header="# de pago" sortable body={pagoNumeroTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="comentarios" header="Comentarios" sortable body={comentariosTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            {/* <Column field="comision" header="Comisión" sortable body={comisionodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="usuarioPago" header="Usuario que recibe pago" sortable body={usuarioPagoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                            {/* <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    ) : (
                        <List />
                    )}

                    <Dialog visible={pagosComisionesDialog} style={{ width: "450px" }} header="Crear Abono" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        {/*    {pagoComision.image && <img src={`assets/demo/images/product/${pagoComision.image}`} alt={pagoComision.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                         */}
                        <div className="field">
                            <label htmlFor="calendar">Fecha del Abono</label>
                            <Calendar inputId="calendar" value={fechaAbono} onChange={(e) => onInputChange(e, "fechaCreacion")} dateFormat="mm/dd/yy"></Calendar>
                        </div>
                        <div className="field">
                            <label htmlFor="comentarios">Observaciones</label>
                            <InputTextarea id="comentarios" value={comentario} onChange={(e) => onInputChange(e, "comentarios")} required autoFocus className={classNames({ "p-invalid": submitted && !pagoComision.comentarios })} />
                            {submitted && !pagoComision.comentarios && <Message severity="error" text="Comentarios es requerida." />}
                        </div>
                        <div className="field">
                            <label htmlFor="cuentas">Cuentas</label>
                            <Dropdown value={cuenta} onChange={(e) => onInputChange(e, "ksCuentas")} options={cuentas} optionLabel="cuenta" placeholder="Selecciona" />
                        </div>
                        <div className="field">
                            <label htmlFor="formaPago">Forma de Pago</label>
                            <Dropdown value={formaPago} onChange={(e) => onInputChange(e, "ksFormaPago")} options={formaPagoDropdown} optionLabel="formaPago" placeholder="Selecciona" />
                        </div>
                        <div className="field">
                            <label htmlFor="ventas">Ventas</label>
                            <Dropdown value={venta} options={ventasDropdown} onChange={(e) => onInputChange(e, "ksVenta")} placeholder="Selecciona una venta:" />
                        </div>
                        <div className="field">
                            <label htmlFor="detalleVentas">ID Detalle Venta</label>
                            <Dropdown value={detalleVentaSeleccionada} options={detalleVenta} onChange={(e) => onDetalleVentaChange(e, "ksDetalleVenta")} placeholder="Selecciona una venta:" />
                        </div>
                        <div className="field">
                            <label htmlFor="numeroAbonoActual">Numero de Abono</label>
                            <InputNumber value={numeroAbonoActual} readOnly={true} />
                        </div>
                        <div className="field">
                            <label htmlFor="saldoActual">Saldo Actual</label>
                            <InputNumber value={saldoActual} readOnly={true} mode="decimal" maxFractionDigits={2} locale="en-US" onChange={(e) => onInputNumberChange(e, "saldoActual")} />
                        </div>

                        <div className="field">
                            <label htmlFor="importe">Importe: {validacionImporte && <p style={{ color: "red" }}>{validacionImporte}</p>}</label>

                            <InputNumber value={importe} onChange={(e) => onInputNumberChange(e, "importe")} showButtons mode="decimal" maxFractionDigits={2} locale="en-US" placeholder="Introduce el importe" min={0} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {pagoComision && (
                                <span>
                                    Are you sure you want to delete <b>{pagoComision.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {pagoComision && <span>Are you sure you want to delete the selected abonos?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Abonos, comparisonFn);
